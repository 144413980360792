import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { HttpParams } from "@angular/common/http";
import { FormBuilder, Validators } from "@angular/forms";

import { DonationService } from "@data/services/donation.service"
import { GiftType } from "@interfaces/enums/gift-type.enum";
import { PaymentType } from "@interfaces/enums/payment-type.enum";
import { SelectPaymentTypeComponent } from "@features/select-payment-type/select-payment-type.component";
import { SelectGiftTypeComponent } from "@features/select-gift-type/select-gift-type.component";
import { TributeComponent } from '@features/tribute/tribute.component';
import { RecurrenceComponent } from "@features//recurrence/recurrence.component";
import { HelperService } from "@core/services/helper.service";
import { LoggerService } from "@core/services/logger.service";
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import { ConstantsService } from "@core/services/constants.service";
import { IBbRecurrence } from "@interfaces/blackbaud-donation/bb-recurrence";
import {CountryService} from "@data/services/country.service";
import {GiftService} from "@data/services/gift.service";

@Component({
    selector: 'app-gift-review',
    templateUrl: './gift-review.component.html',
    styleUrls: ['./gift-review.component.css'],
    standalone: false
})
export class GiftReviewComponent implements OnInit {

  constructor(private readonly router: Router,
              public readonly donationService: DonationService,
              public readonly giftService: GiftService,
              private readonly spinnerService: NgxSpinnerService,
              private readonly logger: LoggerService,
              private readonly titleService: Title,
              private readonly countryService: CountryService,
              private readonly formBuilder: FormBuilder) {

    this.titleService.setTitle(`${ConstantsService.pageTitle} Review`);

  }

  giftType!: GiftType;
  GiftType = GiftType;
  PaymentType = PaymentType;

  total: number;
  displayGifts: boolean = false;

  constants = ConstantsService;

  giftReviewForm = this.formBuilder.group({
    specialInstructions: ["", Validators.maxLength(ConstantsService.attributeTextLength)]
  });

  get specialInstructions() { return this.giftReviewForm.get("specialInstructions"); }

  @ViewChild(SelectPaymentTypeComponent, { static: true }) paymentTypeComponent: SelectPaymentTypeComponent;
  @ViewChild(SelectGiftTypeComponent, { static: true }) giftTypeComponent: SelectGiftTypeComponent;
  @ViewChild(TributeComponent) tributeComponent: TributeComponent;
  @ViewChild(RecurrenceComponent) recurrenceComponent: RecurrenceComponent;

  attempts: number = 0;

  /**
   * Route to Your Info component
   */
  yourInfoClick(): void {
    this.logger.debug("gift-review.component.yourInfoClick")
    this.logger.debug("gift-review.component.yourInfoClick | location: ", window.location.host);

    // Update recurrence on donationService from child form
    // If gift type is not recurring clear
    if (this.donationService.giftType !== GiftType.Recurring) {
      this.donationService.recurrence = null;
    } else {
      // create a new recurrence object from the form fields
      const form = this.recurrenceComponent.form;
      const recurrence: IBbRecurrence = {
        startDate: form.get("startDate").value,
        frequency: form.get("frequency").value
      };
      this.logger.debug("gift-review.component.yourInfoClick | recurrence: ", recurrence);
      this.donationService.recurrence = recurrence;
    }

    // Update comments on donationService
    this.donationService.comments = this.specialInstructions.value;

    // Validate child forms
    this.paymentTypeComponent.submitting = true;
    this.giftTypeComponent.submitting = true;
    if (this.tributeComponent) {
      this.tributeComponent.submitting = true;
    }
    if (this.recurrenceComponent) {
      this.recurrenceComponent.submitting = true;
    }

    if (this.paymentTypeComponent.form.invalid || this.giftTypeComponent.form.invalid || this.giftReviewForm.invalid) {
      this.logger.debug(`gift-review.component.yourInfoClick | Form invalid. Attempts: ${this.attempts}`);
      this.attempts ++;
      return;
    }
    if (this.tributeComponent?.form?.invalid){
      this.logger.debug(`gift-review.component.yourInfoClick | Tribute form invalid. Attempts: ${this.attempts}`);
      this.attempts ++;
      return;
    }
    if (this.tributeComponent?.acknowledgeeComponent?.acknowledgeeForm?.invalid)
    {
      this.logger.debug(`gift-review.component.yourInfoClick | Acknowledgee form invalid. Attempts: ${this.attempts}`);
      this.attempts ++;
      return;
    }
    if (this.recurrenceComponent
      && ((!this.donationService.recurrence?.frequency && this.donationService.paymentType === PaymentType.UnifiedCheckout)
      || (this.recurrenceComponent?.form?.invalid))) {

      this.logger.debug(`gift-review.component.yourInfoClick | Recurrence form invalid. Attempts: ${this.attempts}`);
      this.attempts ++;
      return;
    }

    switch (this.donationService.paymentType) {
      case PaymentType.PayrollDeduction:

        let host: string = window.location.host;
        const localhost: boolean = host.includes("localhost");
        let returnUrl: string = `/home/payrollconfirmation/`;

        // HACK: this requires an update if we change the virtual directory name. Update to retrieve virtual directory
        returnUrl = `${(localhost) ? "http://" : "https://"}${host}${(!localhost) ? "/giving" : ""}${returnUrl}`;

        this.logger.debug("ReturnUrl: ", returnUrl);

        window.location.href = `${returnUrl}`;

        break;
      case PaymentType.UnifiedCheckout:
      case PaymentType.Pledge:
        this.router.navigateByUrl("/your-info");
        HelperService.goToTop();
        break;
    }

  }

  /**
   * Checks for query string parameters and attempts to parse them and route to component accordingly
   */
  parseParams(): void {
    this.logger.debug("gift-review.component.parseParams");

    this.logger.loggerReady().then(
      data => {
        const fundIdParam = "id";
        const appealIdParam = "appealId";
        const url: string = window.location.href;

        this.logger.debug(`gift-review.component.parseParams | URL: ${url}`);

        let confirmationValue: string;
        let fundIdValue: string;
        let appealIdValue: string;

        // If there is a query string in the url attempt to parse for known params
        if (url.includes("?")) {
          const httpParams = new HttpParams({ fromString: url.split("?")[1] });

          // Attempt to get a fund id
          fundIdValue = httpParams.get(fundIdParam);

          this.logger.debug(`gift-review.component.parseParams | Fund Id: ${fundIdValue}`)

          if (fundIdValue) {
            this.displayGifts = false;
            this.router.navigate(["/gift-referral/", fundIdValue]);
          }

          // Try to get an appeal id and route to add-gift
          appealIdValue = httpParams.get(appealIdParam);
          this.logger.debug(`gift-review.component.parseParams | Appeal Id: ${appealIdValue}`)
          if (appealIdValue) {
            this.logger.debug(`gift-review.component.parseParams | Appeal id found. Route to add-gift with id`);
            this.displayGifts = false;
            this.router.navigate(["/add-gift/", appealIdValue]);
          }

        }

        // If it isn't a confirmation or referral display gifts
        this.displayGifts = true;

      });
  }

  ngOnInit(): void {
    this.logger.debug("gift-review.component.ngOnInit");

    this.spinnerService.show(undefined, ConstantsService.defaultSpinnerOptions);

    // Prep countries. Not actually used in this component but makes loading of your-info form faster
    this.logger.debug(`gift-review.component.ngOnInit | Loading countries`)
    this.countryService.getCountries().then(countries => {
      this.logger.trace(`gift-review.component.ngOnInit | Countries: `, countries);
      // Load US
      this.countryService.getCountry(ConstantsService.unitedStatesGuid).then(country => {
        this.logger.trace(`gift-review.component.ngOnInit | Country: `, country);
      });
    });

    // Subscribe to donationService.giftType to update local variable
    this.donationService.giftType$.subscribe(giftType => {
      this.logger.debug("gift-review.component.giftType$.subscribe | giftType: ", giftType);
      this.giftType = giftType;
    });

    // Subscribe to gift total to update local variable
    this.giftService.giftTotal$.subscribe(total => {
      this.logger.debug("gift-review.component.giftTotal$.subscribe | total: ", total);
      this.total = total;
    });


    // Get comments and update form
    this.specialInstructions.setValue(this.donationService.comments);

    // Check for param values before loading page
    this.parseParams();

  }

}
