import { Component, OnInit, Input } from '@angular/core';
import { IDesignation } from "@interfaces/designation";
import { LoggerService } from "@core/services/logger.service";
import { IAppealCode } from "@interfaces/appeal-code";

@Component({
    selector: 'app-fund-description',
    templateUrl: './fund-description.component.html',
    styleUrls: ['./fund-description.component.css'],
    providers: [LoggerService],
    standalone: false
})
export class FundDescriptionComponent implements OnInit {

  @Input() fund: IDesignation;
  @Input() showLink: boolean = false;

  fundSelectionDescriptionPanelOpen: boolean = true;
  appealCode: IAppealCode;

  constructor(private logger: LoggerService) {
    this.logger.debug("fund-description.component.constructor");
  }

  onAppealCodeChanged(result: IAppealCode) {
    this.appealCode = result;
  }

  /**
   * Copy the link text to the user's clipboard
   */
  copyClick(directLinkElement) {
    this.logger.debug("copyClick | LinkElement: ", directLinkElement.value);
    directLinkElement.select();
    document.execCommand('copy');
    directLinkElement.setSelectionRange(0, 0);
  }

  ngOnInit() {
    this.logger.debug("fund-description.component.ngOnInit");
  }
}
