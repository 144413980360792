import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Validators, FormBuilder} from "@angular/forms";
import { Subscription } from 'rxjs';
import { DonationService } from "@data/services/donation.service";
import { AcknowledgeeComponent } from "@features/acknowledgee/acknowledgee.component";
import { ConstantsService } from "@core/services/constants.service";

import { LoggerService } from "@core/services/logger.service";
import { TextValidators } from '@core/validators/text-validators';
import {ITribute} from "@interfaces/tribute";

@Component({
    selector: 'app-tribute',
    templateUrl: './tribute.component.html',
    styleUrls: ['./tribute.component.css'],
    providers: [LoggerService],
    standalone: false
})
export class TributeComponent implements OnInit, OnDestroy {

  constructor(public readonly donationService: DonationService,
              private readonly formBuilder: FormBuilder,
              private readonly logger: LoggerService) { }

  inHonorOrMemory: boolean = false;

  tributeSubscription: Subscription;

  constants = ConstantsService;

  form = this.formBuilder.group({
    firstName: [],
    lastName: [],
    tributeType: []
  });

  @ViewChild(AcknowledgeeComponent) acknowledgeeComponent: AcknowledgeeComponent;

  submitting: boolean = false;

  /**
   * Capture change event of In Honor or Memory checkbox and create or destroy tribute on donation service
   * @param event
   */
  onInTributeChanged(event) {
    this.logger.debug('tribute.component.onInTributeChanged');

    if (event.checked) {
      this.inHonorOrMemory = true;
      if (!this.donationService.tribute) {
      this.donationService.tribute = {
          tributeDefinition: {
            firstName: "",
            lastName: "",
            type: "",
            description: ""
          }
        }
      }
      // Add validators
      this.logger.debug('tribute.component.onInTributeChanged | Add validators')
      this.firstName.setValidators([Validators.required, Validators.maxLength(ConstantsService.firstNameCharacterLimit), TextValidators.exludeSpecialCharacters()]);
      this.lastName.setValidators([Validators.required, Validators.maxLength(ConstantsService.lastNameCharacterLimit), TextValidators.exludeSpecialCharacters()]);
      this.tributeType.setValidators([Validators.required]);
      this.form.reset();
      this.logger.debug('tribute.component.onInTributeChanged | Subscribe to changes')
      this.watchChanges();
    } else {
      this.inHonorOrMemory = false;
      this.logger.debug('tribute.component.onInTributeChanged | Unsubscribe from changes to form')
      this.tributeSubscription.unsubscribe();
      this.donationService.tribute = null;
      // Remove validators
      this.logger.debug('tribute.component.onInTributeChanged | Remove validators');
      this.firstName.clearValidators();
      this.lastName.clearValidators();
      this.tributeType.clearValidators();
      this.firstName.updateValueAndValidity();
      this.lastName.updateValueAndValidity();
      this.tributeType.updateValueAndValidity();
      // Clear form values
      this.firstName.setValue("");
      this.lastName.setValue("");
      this.tributeType.setValue("");
    }
  }

  // Getters for controls
  get firstName() { return this.form.get("firstName"); }
  get lastName() { return this.form.get("lastName"); }
  get tributeType() { return this.form.get("tributeType"); }

  get inHonorOfText() { return ConstantsService.inHonorText; }
  get inMemoryOfText() { return ConstantsService.inMemoryText; }

  /** Watch for changes and update donation object */
  watchChanges(): void {
    this.logger.debug('tribute.component.watchChanges');
    this.tributeSubscription = this.form.valueChanges.subscribe(value => {
      if (!this.inHonorOrMemory) {
        return;
      }
      this.logger.debug(`tribute.component.watchChanges | value: ${JSON.stringify(value)}`);
      const tribute: ITribute = {
        tributeDefinition: {
          firstName: value.firstName,
          lastName: value.lastName,
          type: value.tributeType,
          description: ""
        }
      }
      this.logger.debug(`tribute.component.watchChanges | tribute: ${JSON.stringify(tribute)}`);
      this.donationService.tribute = tribute;
    });
  }

  ngOnInit() {
    this.logger.debug('tribute.component.ngOnInit');
    if (this.donationService.tribute) {
      this.inHonorOrMemory = true;
      // Get initial values
      this.firstName.setValue(this.donationService.tribute.tributeDefinition.firstName);
      this.lastName.setValue(this.donationService.tribute.tributeDefinition.lastName);
      this.tributeType.setValue(this.donationService.tribute.tributeDefinition.type);
    }

    this.watchChanges();

  }

  ngOnDestroy() {
    this.tributeSubscription.unsubscribe();
  }

}
