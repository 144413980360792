import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { LoggerService } from "@core/services/logger.service";
import { IAppealCode } from "@interfaces/appeal-code";
import { AppealCodeService } from "@data/services/appeal-code.service";

@Component({
    selector: 'app-appeal-code',
    templateUrl: './appeal-code.component.html',
    styleUrls: ['./appeal-code.component.css'],
    providers: [LoggerService],
    standalone: false
})
export class AppealCodeComponent implements OnInit {
  @Input() appealCodeOnly: boolean;
  @Output() appealCodeSelectionChanged = new EventEmitter<IAppealCode>();

  hasAppealCode: boolean = false;
  appealCodes: IAppealCode[];
  selectedAppealCode: IAppealCode;

  constructor(private appealService: AppealCodeService,
              private logger: LoggerService) { }

  /**
   * Retrieve appeal codes from CRM
   */
  getAppealCodes(): void {
    this.logger.debug(`appeal-code.component.getAppealCodes`);
    this.appealService.getAppealCodes().then(
      data => {
        this.logger.debug("Appeal codes: ", data);
        this.appealCodes = data;
      });
  }

  onAppealCodeChange(result: IAppealCode): void {
    this.logger.debug(`appeal-code.component.onAppealCodeChange | result: `, result);
    this.appealCodeSelectionChanged.emit(result);
    this.selectedAppealCode = result;
  }

  /**
   * If has appeal is changed, clear the selected appeal so that it does not get included in the link
   * @param event - Checkbox
   */
  onHasAppealCodeChanged(event) {
    this.logger.debug(`appeal-code.component.onHasAppealCodeChanged | event: ${event}`);
    if (!event.checked) {
      this.onAppealCodeChange(null);
    }
  }

  ngOnInit() {
    this.logger.debug('appeal-code.component.ngOnInit');
    this.getAppealCodes();
    this.logger.debug('appeal-code.component | appeal code only: ', this.appealCodeOnly);
  }

}
