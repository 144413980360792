import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";

import { DonationService } from "@data/services/donation.service";
import { Acknowledgee } from "@interfaces/blackbaud-donation/bb-acknowledgee";

import { LoggerService } from "@core/services/logger.service";
import { ConstantsService } from "@core/services/constants.service";
import { TextValidators } from '@core/validators/text-validators';

@Component({
    selector: 'app-acknowledgee',
    templateUrl: './acknowledgee.component.html',
    styleUrls: ['./acknowledgee.component.css'],
    providers: [LoggerService],
    standalone: false
})
export class AcknowledgeeComponent implements OnInit {

  constructor(public readonly donationService: DonationService,
              private formBuilder: UntypedFormBuilder,
              private logger: LoggerService) {
    this.logger.debug("acknowledgee.component.constructor");
  }

  isAcknowledgee: boolean = false;

  acknowledgeeForm: UntypedFormGroup;

  showAddress: boolean = false;

  public constants = ConstantsService;

  // Form fields
  get firstName() { return this.acknowledgeeForm.get("firstName"); }
  get lastName() { return this.acknowledgeeForm.get("lastName"); }



  /**
   * Watch for updates to the acknowledgee checkbox
   * @param event checkbox event
   */
  onIsAcknowledgeeChanged(event) {
    this.logger.debug('acknowledgee.component.onIsAcknowledgeeChanged | checked: ', event.checked);
    if (event.checked) {
      this.logger.debug("acknowledgee.component.onIsAcknowledgeeChanged | Acknowledgee: ", this.donationService.acknowledgee);
      this.showAddress = true;
      // Enable validators
      this.firstName.setValidators([Validators.required, Validators.maxLength(ConstantsService.firstNameCharacterLimit), TextValidators.exludeSpecialCharacters()]);
      this.lastName.setValidators([Validators.required, Validators.maxLength(ConstantsService.lastNameCharacterLimit), TextValidators.exludeSpecialCharacters()]);
      this.acknowledgeeForm.get("country").setValidators([Validators.required]);
      this.acknowledgeeForm.get("addressLines").setValidators([Validators.required, Validators.maxLength(ConstantsService.addressLinesCharacterLimit)]);
      this.acknowledgeeForm.get("city").setValidators([Validators.required, Validators.maxLength(ConstantsService.cityCharacterLimit), TextValidators.exludeSpecialCharacters()]);
      this.acknowledgeeForm.get("state").setValidators([Validators.required]);
      this.acknowledgeeForm.get("postalCode").setValidators([Validators.required]);
    } else {
      // Disable validators
      this.logger.debug("acknowledgee.component.onIsAcknowledgeeChanged | Disabling validators");
      this.acknowledgeeForm.get("firstName").setValidators(null);
      this.acknowledgeeForm.get("lastName").setValidators(null);
      this.acknowledgeeForm.get("country").setValidators(null);
      this.acknowledgeeForm.get("addressLines").setValidators(null);
      this.acknowledgeeForm.get("city").setValidators(null);
      this.acknowledgeeForm.get("state").setValidators(null);
      this.acknowledgeeForm.get("postalCode").setValidators(null);
      // Clear object
      this.donationService.acknowledgee = null;
      // Clear form
      this.acknowledgeeForm.reset();
    }
  }

  /**
   * Save values from form into donation service acknowledgee
   * @param acknowledgee Data that is going to be used to persist
   * to the donation service
   */
  private saveAcknowledgee(acknowledgee: Acknowledgee): void {
    this.logger.debug('acknowledgee.component.saveAcknowledgee | acknowledgee: ', acknowledgee);
    this.donationService.acknowledgee = acknowledgee;
  }

  /** Retrieve acknowledgee information from donation service */
  private getAcknowledgee(): void {
    this.logger.debug('acknowledgee.component.getAcknowledgee');
    this.acknowledgeeForm.setValue(this.donationService.acknowledgee);
    this.showAddress = true;
  }

  /** Watch for form changes and update the donation acknowledgee object */
  private onFormChanges(): void {
    this.logger.debug('acknowledgee.component.onFormChanges');
    this.acknowledgeeForm.valueChanges.subscribe(val => {
      this.logger.debug("acknowledgee.component.onFormChanges | Form values changed: ", val);
      // Call constructor to create an object of type Acknowledgee
      const acknowledgee = new Acknowledgee(<Acknowledgee>val);
      // Save
      this.saveAcknowledgee(acknowledgee);
    });
  }

  ngOnInit() {
    this.logger.debug('acknowledgee.component.ngOnInit');
    this.acknowledgeeForm = this.formBuilder.group({
      firstName: [],
      lastName: [],
      country: [],
      addressLines: [],
      apt: [],
      city: [],
      state: [],
      postalCode: []
    });

    this.logger.debug("acknowledgee.component.ngOnInit | Acknowledgee: ", this.donationService.acknowledgee);
    if (this.donationService.acknowledgee?.firstName) {
      this.isAcknowledgee = true;

      // Load acknowledgee info from donation service
      this.getAcknowledgee();
    }

    this.onFormChanges();
  }
}
