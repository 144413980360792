import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { LoggerService } from "@core/services/logger.service";

@Component({
    selector: 'app-gift-amount',
    templateUrl: './gift-amount.component.html',
    styleUrls: ['./gift-amount.component.css'],
    providers: [LoggerService],
    standalone: false
})
export class GiftAmountComponent implements OnInit {

  constructor(private readonly logger: LoggerService) {
  }

  @Output() amountChanged = new EventEmitter<number>();
  @Input() amount: number;
  useOtherAmount: boolean = false;

  amountChange(amount: number) {
    this.logger.debug('gift-amount.component.amountChange | amount:', amount);
    this.amountChanged.emit(amount);
    this.amount = amount;
  }

  /**
   * Capture the amount selected on the form using the click event
   * @param event
   */
  onAmountOptionsClick(event) {
    this.logger.debug('gift-amount.component.onAmountOptionsClick | event:', event);

    let target = event.target || event.srcElement || event.currentTarget;

    if (target.children.length > 0) {
      const optionId = target.children[0].id;

      const optionAmount = optionId.substring(6);

      if (!isNaN(optionAmount)) {
        this.amount = +optionAmount;
        this.amountChanged.emit(+optionAmount);
      }
      this.useOtherAmount = optionId === "optionOther";
    }
  }

  /**
   * Capture changes to the other amount field
   * @param amount
   */
  onOtherAmountChanged(amount: number) {
    this.amountChanged.emit(+amount);
  }

  ngOnInit() {
    this.logger.debug('gift-amount.component.ngOnInit | amount:', this.amount);

    // If the amount is not one of the standard options use OtherAmount
    this.useOtherAmount = !([-1, 25, 50, 100, 250, 500, 1000, 2500, 5000].includes(this.amount));

    if (this.amount === -1) {
      this.amount = 0;
    }

    this.logger.debug('gift-amount.component.ngOnInit | amount:', this.amount);

    this.logger.trace('gift-amount.component.ngOnInit | useOtherAmount:', this.useOtherAmount);

  }

}
