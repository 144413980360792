import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-matching-gift-search',
    templateUrl: './matching-gift-search.component.html',
    styleUrls: ['./matching-gift-search.component.css'],
    standalone: false
})
export class MatchingGiftSearchComponent implements OnInit {

  constructor() { }

  onLoad():void {

  }


  ngOnInit() {
  }

}
