import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppConfigService } from "@core/services/app-config.service";
import { ITribute } from "@interfaces/tribute";
import { IDonation, } from "@interfaces/donation";
import { IGift } from "@interfaces/gift";
import { GiftService } from "@data/services/gift.service";
import { GiftType } from "@interfaces/enums/gift-type.enum";
import { PaymentType } from "@interfaces/enums/payment-type.enum";
import { IBbRecurrence } from "@interfaces/blackbaud-donation/bb-recurrence";
import { RecurrenceType } from "@interfaces/enums/recurrence-type.enum"
import { Acknowledgee } from "@interfaces/blackbaud-donation/bb-acknowledgee";
import { ISsoUser } from "@interfaces/sso-user";

import { ConstantsService } from "@core/services/constants.service";

import { LoggerService } from "@core/services/logger.service";
import {LocalStorageService} from "@core/services/local-storage.service";
import {DonorService} from "@data/services/donor.service";

@Injectable({
  providedIn: 'root'
})
export class DonationService {

  constructor(private httpClient: HttpClient,
              private readonly appConfigService: AppConfigService,
              private readonly giftService: GiftService,
              private readonly logger: LoggerService,
              private readonly localStorage: LocalStorageService,
              private readonly donorService: DonorService) {  }

  private httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.appConfigService.config.webApi.token
    })
  };

  token: string = this.appConfigService.config.webApi.token;

  private apiUrl: string = this.appConfigService.config.webApi.url;

  private _ssoUser: ISsoUser;
  get ssoUser(): ISsoUser {
    return this._ssoUser;
  }
  set ssoUser(value: ISsoUser) {
    this._ssoUser = value;
  }

  /** DO NOT read from tribute directly in html template. Subscribe to tribute$ subject and use a local variable */
  get tribute(): ITribute {
    return this.localStorage.getItem<ITribute>(ConstantsService.localTributeKey);
  }
  set tribute(value: ITribute) {
    this.localStorage.setItem(ConstantsService.localTributeKey, value);
    this.tribute$.next(value);
  }
  tribute$ = new BehaviorSubject<ITribute>(this.tribute);

  /** DO NOT read from giftType directly in html template. Subscribe to giftType$ subject and use a local variable */
  get giftType(): GiftType {
    return this.localStorage.getItem(ConstantsService.localGiftTypeKey);
  }
  set giftType(value: GiftType) {
    this.localStorage.setItem(ConstantsService.localGiftTypeKey, value);
    this.giftType$.next(value);
  }
  // add behavior subject to track changes to gift type
  giftType$ = new BehaviorSubject<GiftType>(this.giftType);


  /** DO NOT read from paymentType directly in html template. Subscribe to paymentType$ subject and use a local variable */
  get paymentType(): PaymentType {
    return this.localStorage.getItem(ConstantsService.localPaymentTypeKey);
  }
  set paymentType(value: PaymentType) {
    this.localStorage.setItem(ConstantsService.localPaymentTypeKey, value);
    this.paymentType$.next(value);
  }
  paymentType$ = new BehaviorSubject<PaymentType>(this.paymentType);

  get recurrence(): IBbRecurrence {
    return this.localStorage.getItem<IBbRecurrence>(ConstantsService.localRecurrenceKey);
  }
  set recurrence(value: IBbRecurrence) {
    if (value?.startDate) {
      const startDate = new Date(value.startDate);
      value.dayOfMonth = startDate.getDate();
    }
    this.localStorage.setItem(ConstantsService.localRecurrenceKey, value);
  }

  /** DO NOT read from comments directly in html template. Subscribe to comments$ subject and use a local variable */
  get comments(): string {
      return this.localStorage.getItem(ConstantsService.localCommentsKey);
  }
  set comments(value: string) {
    this.localStorage.setItem(ConstantsService.localCommentsKey, value);
    this.comments$.next(value);
  }
  comments$ = new BehaviorSubject<string>(this.comments);

  /** DO NOT read directly from acknowledgee in html template */
  get acknowledgee(): Acknowledgee {
    return this.localStorage.getItem<Acknowledgee>(ConstantsService.localAcknowledgeeKey);
  }
  set acknowledgee(value: Acknowledgee) {
    this.localStorage.setItem(ConstantsService.localAcknowledgeeKey, value);
  }

  get donationId(): string {
    return this.localStorage.getItem(ConstantsService.localDonationIdKey);
  }
  set donationId(value: string) {
    this.localStorage.setItem(ConstantsService.localDonationIdKey, value);
  }

  processorTransactionId: string;

  /**
   * Create a non BBPS donation and sent to web api to commit to CRM
   * If we have an existing donation id, the api will update the existing record
   */
  async createOnlineGivingDonation(): Promise<string> {
    this.logger.debug(`donation.service.createOnlineGivingDonation`);

    let donation = await this.generateBaseDonationObject();

    this.logger.trace(`donation.service.createOnlineGivingDonation | donation`, donation)

    // Add existing donation id
    donation.id = this.donationId;

    donation.donor = this.donorService.donor;

    // Get payment method
    donation.gift.paymentMethod = this.paymentType;

    if (donation.gift.designations.length === 0) {
      throw new Error(ConstantsService.noGiftsInCart);
    }

    this.logger.debug("donation.service.createOnlineGivingDonation | final donation: ", donation);

    this.logger.debug(`donation.service.createOnlineGivingDonation | apiUrl: ${this.apiUrl}`);

    const result = this.httpClient.post<string>(this.apiUrl + "/Donation", donation, this.httpOptions).toPromise();

    this.logger.trace(`donation.service.createOnlineGivingDonation | result: `, result);

    return result;

  }

  /**
   * Complete a non BBPS donation and save processor transaction id
   */
  async completeOnlineGivingDonation(id: string, processorTransactionId: string): Promise<boolean> {
    this.logger.debug(`donation.service.completeOnlineGivingDonation | id: ${id} | processorTransactionId: ${processorTransactionId}`);

    const url = `${this.apiUrl}/donation/completedonation/${id}/${processorTransactionId}`;
    const result = this.httpClient.post<boolean>(url, "", this.httpOptions).toPromise();

    this.logger.trace(`donation.service.completeOnlineGivingDonation | result: `, result);

    return result;
  }

  /**
   * In the event of a failed transaction update the donation transaction status and failure data
   * @param id Id of failed transaction
   * @param failureData Processor response or error
   * @param processorTransactionId Processor transaction id
   */
  async updateOnlineGivingDonationWithFailure(id: string, failureData: string, processorTransactionId: string): Promise<boolean> {
    this.logger.debug(`donation.service.updateOnlineGivingDonationWithFailure | id: ${id} | failureData: ${failureData} | processorTransactionId: ${processorTransactionId}`);

    const url = `${this.apiUrl}/donation/donationFailed`;

    let payload: any = {
      id: id,
      failureData: failureData,
      processorTransactionId: processorTransactionId
    };

    const result = this.httpClient.post<boolean>(url, payload, this.httpOptions).toPromise();

    this.logger.trace(`donation.service.updateOnlineGivingDonationWithFailure | result: `, result);

    return result;
  }

  /**
   * Retrieve a donation stored in the Online Giving Donation Transactions (non BBPS donation)
   * @param id Donation ID
   */
  async getOnlineGivingDonation(id: string): Promise<IDonation> {
    this.logger.debug(`donation.service.getOnlineGivingDonation | id: ${id}`);

    const url = `${this.apiUrl}/donation/${id}`;

    return this.httpClient.get<IDonation>(url, this.httpOptions)
      .pipe(tap(data => this.logger.debug("donation.service.getOnlineGivingDonation | result: ", data)))
      .toPromise();

  }

  /**
   * Send an acknowledgement to the user
   * @param donation
   */
  async sendAcknowledgementWithDonation(donation: IDonation): Promise<string> {
    this.logger.debug('donation.service.sendAcknowledgementWithDonation');
    this.logger.trace('donation.service.sendAcknowledgementWithDonation | donation: ', donation);

    const url: string = `${this.apiUrl}/Acknowledgement`;
    return this.httpClient.post<string>(url, donation, this.httpOptions)
      .pipe(tap(data => this.logger.trace('donation.service.sendAcknowledgementWithDonation | result: ', data)))
      .toPromise();

  }

  /** Generate a donation object and call sendAcknowledgementWithDonation --*/
  async sendAcknowledgement(): Promise<string> {
    this.logger.debug('donation.service.sendAcknowledgement');
    let donation = await this.generateBaseDonationObject();
    donation.donor = this.donorService.donor;
    this.logger.trace('donation.service.sendAcknowledgement | donation: ', donation);
    const result = this.sendAcknowledgementWithDonation(donation);
    this.logger.trace('donation.service.sendAcknowledgement | result: ', result);
    return result;
  }

  async generateBaseDonationObject(): Promise<IDonation> {
    this.logger.debug('donation.service.generateBaseDonationObject');
    let gifts: IGift[];
    let appealId: string;

    await this.giftService.getGifts().then().then(
      data => {
        this.logger.trace('donation.service.generateBaseDonationObject | gifts: ', data);
        gifts = data;
      });

    this.logger.debug('donation.service.generateBaseDonationObject | create designations');

    let designations = [];
    gifts.forEach(g => {
      designations.push({
        "id": g.designation.id,
        "amount": g.amount
      });
      // If there is an appeal id on the gift, capture it
      // There is only one appeal id per donation, so we'll take whatever one we find here
      if (g.appealId) {
        appealId = g.appealId;
      }
      this.logger.debug("donation.service.generateBaseDonationObject | appealId: ", appealId);
    });

    // Create donation object from Donor and gifts

    let donation: IDonation = {
      // Donor: donor,
      origin: {
        pageId: 624,
        appealId: appealId
      },
      gift: {
        isCorporate: this.donorService.isCorporate,
        isAnonymous: this.donorService.isAnonymous,
        paymentMethod: 0, // Credit Card
        designations: designations,
        attributes: [
          {
            attributeId: ConstantsService.espiGiftTypeAttributeCode, // ESPI Gift Type
            value: this.getGiftTypeId(this.giftType),
          },
          {
            attributeId: ConstantsService.espiRecurringAttributeCode, // Recurring
            value: (this.giftType === GiftType.Recurring) ? "true" : "false"
          }
        ],
      },

    };

    // Only add Recurrence object if one exists
    if (this.recurrence) {
      const recurrence = this.recurrence;

      this.logger.trace('donation.service | recurrence: ', recurrence);

      // Recreate the date object
      const startDate = new Date(recurrence.startDate);

      recurrence.startDate = startDate;
      this.recurrence.startDate = startDate;

      donation.gift.recurrence = recurrence;
      // If it is an annual gift set the month field
      this.logger.trace('donation.service | recurrence: ', recurrence);
      const frequency = Number(this.recurrence.frequency) as RecurrenceType;
      if (frequency === RecurrenceType.Annually) {
        donation.gift.recurrence.month = startDate.getMonth() + 1; // getMonth is 0 based
      }
    }

    let giftInstructions: string = "";

    // Add tribute if one was set and it is not a recurring gift
    if (this.tribute && !this.recurrence) {
      let tribute = this.tribute;

      this.logger.trace(`donation.service.generateBaseDonationObject | tribute: `, tribute);

      tribute.tributeDefinition.description = `${this.tribute.tributeDefinition.type} ${this.tribute.tributeDefinition.firstName} ${this.tribute.tributeDefinition.lastName}`

      this.tribute = tribute;
      donation.gift.tribute = tribute;

      this.logger.trace(`donation.service.generateBaseDonationObject | tribute: `, donation.gift.tribute);

      // Add acknowledgee if one was set
      if (this.acknowledgee?.firstName) {
        // HACK: Creating a new acknowledgee since the one returned from local storage is not a true object and we can't call methods on it
        // Method is just title casing some fields. should we just handle that here instead?
        const acknowledgee = new Acknowledgee(this.acknowledgee);
        this.logger.trace("donation.service.generateBaseDonationObject | acknowledgee ", acknowledgee.getBbAcknowledgee());
        donation.gift.tribute.acknowledgee = acknowledgee.getBbAcknowledgee();

        giftInstructions = `Ack: ${this.acknowledgee.firstName} ${this.acknowledgee.lastName} ${this.acknowledgee.addressLines} ${this.acknowledgee.state.abbreviation} ${this.acknowledgee.postalCode.replace(ConstantsService.noPostalCodeVal, "")}`;

      }
      // Add note to special instruction for gift processing when there is a tribute
      if (!this.comments) {
        this.comments = `See Tribute`;
      } else if (!this.comments.includes("See Tribute")) {
        this.comments = `See Tribute | ${this.comments}`;
      }
    }

    // Add comments as ESPI Gift Instructions attribute
    if (this.comments) {
      // Trim comments to field limit (255)
      donation.gift.comments = this.comments.substring(0, ConstantsService.attributeTextLength);
      this.logger.debug(`donation.service | comments: ${this.comments}`);

      this.logger.debug(`donation.service | trimmedComments: ${donation.gift.comments}`);

      giftInstructions += `Donation Message: ${this.comments}`;
    }

    this.logger.debug(`donation.service | giftInstructions: `, giftInstructions);


    if (giftInstructions) {
      // Trim gift instructions to field limit (255)
      giftInstructions = giftInstructions.substring(0, ConstantsService.attributeTextLength);
      this.logger.debug('donation.service | Trimmed gift instructions: ', giftInstructions);

      donation.gift.attributes.push({ attributeId: ConstantsService.espiGiftInstructionsAttributeCode, value: giftInstructions });
    }

    // Determine merchant account to use
    donation.merchantAccountId = this.getMerchantId(gifts);

    return donation;
  }

  /** Inspects the gifts and determines which merchant account to use */
  getMerchantId(gifts: IGift[]): string {
    this.logger.debug('donation.service.getMerchantId');
    this.logger.trace('donation.service.getMerchantId | gifts', gifts);

    const foundationMerchantId:string = "ucsdfdn";
    const regentsMerchantId: string = "ucsdreg";

    let result: string = "";

    let foundation: boolean = false;
    let regents: boolean = false;

    // Loop through list of gifts and check if account number starts with f or r. If null default to F
    gifts.forEach(g => {
      let category = g.designation.purposeCategory || "Foundation";
      this.logger.debug('donation.service.getMerchantId | category: ', category)

      switch (category) {
        case "Regents":
          regents = true;
          break;
        case "Foundation": // Intentional fallthrough to default
        default:
          foundation = true;
          break;
      }
    });

    this.logger.debug('donation.service.getMerchantId | foundation: ', foundation)
    this.logger.debug('donation.service.getMerchantId | regents: ', regents)

    // Determine which merchant account.
    // Regents if Regents and only regents. Otherwise Foundation
    if (foundation) {
      result = foundationMerchantId;
    } else if (regents) {
      result = regentsMerchantId;
    } else {
      // Default to foundation
      result = foundationMerchantId;
    }

    this.logger.trace('donation.service.getMerchantId | result: ', result);

    return result;
  }

  getGiftTypeId(giftType: GiftType): string {
    this.logger.debug(`donation.service.getGiftTypeId giftType: ${giftType}`);
    let result = "";
    if (giftType === GiftType.PledgePayment) {
      result = ConstantsService.espiGiftTypePledge;
    } else if (giftType === GiftType.Recurring) {
      result = ConstantsService.espiGiftTypeRecurring;
    } else {
      result = ConstantsService.espiGiftTypeNewGift;
    }
    this.logger.trace(`donation.service.getGiftTypeId result: ${result}`);
    return result;
  }

}

