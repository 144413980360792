import {Component, OnInit, ViewChild} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";

import { Donor } from "@interfaces/donor";
import { HelperService } from "@core/services/helper.service";
import { DonationService } from "@data/services/donation.service";
import { PaymentType } from "@interfaces/enums/payment-type.enum";
import { LoggerService } from "@core/services/logger.service";

import { ConstantsService } from "@core/services/constants.service";
import { TextValidators } from '@core/validators/text-validators';

import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { UnifiedCheckoutComponent } from "@features/unified-checkout/unified-checkout.component";
import { DonorService } from "@data/services/donor.service";
import Swal from "sweetalert2";
import {RecaptchaService} from "@data/services/recaptcha.service";

@Component({
    selector: 'app-your-info',
    templateUrl: './your-info.component.html',
    styleUrls: ['./your-info.component.css'],
    providers: [LoggerService],
    standalone: false
})
export class YourInfoComponent implements OnInit {
  constructor(private readonly formBuilder: UntypedFormBuilder,
              private readonly router: Router,
              public readonly donationService: DonationService,
              private readonly donorService: DonorService,
              private readonly logger: LoggerService,
              private readonly spinnerService: NgxSpinnerService,
              private readonly titleService: Title,
              private readonly recaptchaService: RecaptchaService,
              private readonly constants: ConstantsService,
              private readonly dialog: MatDialog) {
    this.titleService.setTitle(`${ConstantsService.pageTitle} Your Info`);
  }

  public ConstantsService = ConstantsService;

  captchaSiteKey: string = this.constants.recaptchaSiteKey;
  @ViewChild('captchaElement') captchaElement: any;
  enableCheckout: boolean = false;
  recaptchaFailed: boolean = false;

  pageTitle: string = "About You";
  yourInfoForm: UntypedFormGroup;
  addressForm: UntypedFormGroup;

  PaymentType = PaymentType;

  showAddress: boolean = false;
  showPostalCode: boolean = true;
  showState: boolean = true;

  errorMessage: string;
  isCorporate: boolean = false;

  armedForces: boolean = false;

  attempts: number = 0;

  readonly armedForcesMsg: string = 'Important: Unfortunately, the secure system we currently use to process credit card gifts is unable to accept billing addresses from American Armed Forces locations. We deeply regret this inconvenience and want to assure you that we are actively pursuing a solution with them. In the meantime, we thank you for your desire to make a gift to UC San Diego and would be happy to facilitate your gift over the phone at (858) 246-1090. You can also email us <a href="mailto:onlinegift@ucsd.edu">here</a> with any questions. Thank you for your service to our country and your support of UC San Diego.';

  onReview() {
    this.logger.debug('your-info.component.onReview');
    this.saveDonorInfo();

    this.router.navigateByUrl("/review");
    HelperService.goToTop();
  }

  onCorporateChange() {
    this.logger.debug('your-info.component.onCorporateChange');
    this.pageTitle = `About You ${(this.isCorporate) ? "& Your Corporation" : ""}`;

    // If corporate box is checked add required to organizationName
    if (this.isCorporate) {
      this.organizationName.setValidators([Validators.required, Validators.maxLength(ConstantsService.lastNameCharacterLimit)]);
    } else {
      this.organizationName.clearValidators();
      this.organizationName.updateValueAndValidity();
      this.organizationName.reset();
    }
  }

  async onSubmit() {
    this.logger.debug('your-info.component.onSubmit');
    // Check if form is valid before submitting
    if (this.yourInfoForm.invalid || this.addressForm.invalid) {
      this.attempts++;
      return;
    }

    // Check if recaptcha has been passed
    if (this.recaptchaFailed) {
      this.logger.debug("your-info.component.onSubmit | Recaptcha not passed");
      await Swal.fire({
        title: "Error",
        text: "ReCaptcha verification failed. Please contact Gift Processing at 858-246-1090 to continue your donation.",
        icon: "error",
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-swal-confirm-button'
        }
      });
      return;
    }

    this.logger.debug("your-info.component.onSubmit | Form valid: ", this.yourInfoForm.valid);

    try {
      await this.spinnerService.show(undefined, ConstantsService.defaultSpinnerOptions);

      await this.saveDonorInfo();

      const donationId = await this.donationService.createOnlineGivingDonation();

      this.logger.debug("your-info.component.onSubmit | Donation Id: ", donationId);
      this.donationService.donationId = donationId;

      // Open unified checkout dialog
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = "950px";
      dialogConfig.width = "650px";
      const dialogRef = this.dialog.open(UnifiedCheckoutComponent, dialogConfig);

      // Make sure that payment cancelled has been reset
      dialogRef.componentInstance.paymentCancelled = false;

      dialogRef.componentInstance.onAuthorized.subscribe( (response) => {
        this.logger.debug(`your-info.component.onSubmit | onAuthorized response: `, response);
        dialogRef.close();

        this.donationService.processorTransactionId = response.id;

        // Route to confirmation page
        this.router.navigateByUrl("/confirmation");

      });
    } catch (err){
      this.logger.error("your-info.component.onSubmit | There was an error processing the donation: ", err.message);
      await this.spinnerService.hide();

      await Swal.fire({
        title: "Error",
        text: ConstantsService.processingErrorMessage,
        icon: "error",
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-swal-confirm-button'
        }
      });
    }
  }

  async saveDonorInfo() {
    this.logger.debug('your-info.component.saveDonorInfo');
    this.yourInfoForm["controls"].address.patchValue(this.addressForm.value);
    this.logger.trace("your-info.component.saveDonorInfo | Form values: ", this.yourInfoForm.value);
    this.donorService.donor = new Donor(<Donor>this.yourInfoForm.value);
    this.donorService.isCorporate = this.isCorporate;
    this.donorService.isAnonymous = this.isAnonymous.value;
  }

  getDonorInfo() {
    this.logger.debug('your-info.component.getDonorInfo');
    let donor: Donor = this.donorService.donor;
    if (donor) {
      this.yourInfoForm.patchValue(donor);
      this.addressForm.setValue(donor.address);
    } else {
      this.logger.debug("your-info.component.getDonorInfo | No donor found");
    }
    this.showAddress = true;
  }

  /**
   * Captures change event on address component and determines whether to allow payment or display message
   * @param armedForces boolean value of whether armed forces was selected
   */
  onStateChange(armedForces: boolean) {
    this.logger.debug('your-info.component.onStateChange | armedForces: ', armedForces);
    this.armedForces = armedForces;
  }

  // Form fields
  get emailAddress() { return this.yourInfoForm.get("emailAddress"); }
  get isAnonymous() { return this.yourInfoForm.get("isAnonymous"); }

  get organizationName() { return this.yourInfoForm.get("organizationName"); }
  get firstName() { return this.yourInfoForm.get("FirstName"); }
  get lastName() { return this.yourInfoForm.get("lastName"); }
  get phone() { return this.yourInfoForm.get("phone"); }


  ngOnInit() {
    this.logger.debug('your-info.component.ngOnInit');

    this.yourInfoForm = this.formBuilder.group({
      organizationName: ["", Validators.maxLength(ConstantsService.lastNameCharacterLimit)],
      FirstName: ["", [Validators.required, Validators.maxLength(ConstantsService.firstNameCharacterLimit), TextValidators.exludeSpecialCharacters()]],
      lastName: ["", [Validators.required, Validators.maxLength(ConstantsService.lastNameCharacterLimit), TextValidators.exludeSpecialCharacters()]],
      phone: ["", Validators.required],
      emailAddress: ["", [Validators.email, Validators.required]],
      isAnonymous: [],
      address: this.addressForm
    });

    this.addressForm = this.formBuilder.group({
      country: ["", Validators.required],
      addressLines: ["", [Validators.required, Validators.maxLength(ConstantsService.addressLinesCharacterLimit)]],
      city: ["", [Validators.required, Validators.maxLength(ConstantsService.cityCharacterLimit), TextValidators.exludeSpecialCharacters()]],
      state: ["", Validators.required],
      postalCode: ["", Validators.required]
    });

    this.getDonorInfo();

    this.isCorporate = this.donorService.isCorporate;
    this.isAnonymous.setValue(this.donorService.isAnonymous);
  }

  onCaptchaReady(): void {
    this.logger.debug('your-info.component.onCaptchaReady');
    // Start recaptcha verification
    this.logger.trace('your-info.component.onCaptchaReady | siteKey: ', this.captchaSiteKey);
    this.captchaElement.execute('secureCheckout');
  }
  onCaptchaLoad(): void {
    this.logger.debug('your-info.component.onCaptchaLoad');
  }
  onCaptchaError(event: any){
    this.logger.error('your-info.component.onError | event: ', event);
    this.recaptchaFailed = true;
  }
  async onCaptchaSuccess(event: any): Promise<void> {
    this.logger.debug('your-info.component.onCaptchaSuccess | event: ', event);

    try {
      // reCAPTCHA is loaded. Verify token
      const response = await this.recaptchaService.verifyRecaptcha(event);
      this.logger.trace('your-info.component.onCaptchaSuccess | response: ', response);

      // Get score from verification and determine if checkout should be enabled
      const score = response.riskAnalysis.score;
      this.logger.debug('your-info.component.onCaptchaSuccess | score: ', score);

      if (score && score >= this.constants.recaptchaThreshold) {
        this.logger.debug('your-info.component.onCaptchaSuccess | Recaptcha verification passed');
        this.enableCheckout = true;
      } else {
        this.logger.fatal(`your-info.component.onCaptchaSuccess | Recaptcha verification failed | score: ${score}`);
        this.enableCheckout = true;
        this.recaptchaFailed = true;
      }
    } catch (err) {
      this.logger.error('your-info.component.onCaptchaSuccess | Error verifying recaptcha: ', err);
      this.enableCheckout = true;
      this.recaptchaFailed = true;
    }
  }
}
