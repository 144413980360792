import { Component, OnInit } from '@angular/core';
import { DonationService } from "@data/services/donation.service";
import { ActivatedRoute, Router, NavigationStart, Event as NavigationEvent } from "@angular/router";
import { filter } from "rxjs/operators";

import { GiftService } from "@data/services/gift.service";
import { IGift } from "@interfaces/gift";
import { IDonor, Donor } from "@interfaces/donor";
import { NgxSpinnerService } from "ngx-spinner";
import { LoggerService } from "@core/services/logger.service";
import { ConstantsService } from "@core/services/constants.service";
import { Title } from '@angular/platform-browser';
import { LocalStorageService } from "@core/services/local-storage.service";
import { DonorService } from "@data/services/donor.service";

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.css'],
    providers: [LoggerService],
    standalone: false
})
export class ConfirmationComponent implements OnInit {

  constructor(public readonly donationService: DonationService,
              private readonly route: ActivatedRoute,
              private readonly giftService: GiftService,
              private readonly spinnerService: NgxSpinnerService,
              private readonly donorService: DonorService,
              private readonly router: Router,
              private readonly logger: LoggerService,
              private readonly localStorage: LocalStorageService,
              private readonly titleService: Title
  ) {
    this.titleService.setTitle(`${ConstantsService.pageTitle} Complete`);
    this.logger.debug(`confirmation.component.constructor`);
    router.events.pipe(
      filter((event: NavigationEvent) => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      this.logger.debug(`confirmation.component.router.events.subscribe | event: ${event}`);
      if (event.restoredState) {
        this.logger.debug("Restoring navigation id: ", event.restoredState.navigationId);
        // Navigate back to the review page rather than to the prior page (checkout or sso)
        router.navigateByUrl("/review");
      }
    });
  }

  pageTitle: string = "Confirmation";
  loadingMsg: string = "Please wait while your confirmation loads...";
  errorMessage: string;
  showConfirmation: boolean = false;
  gifts: IGift[];
  firstName: string;

  ngOnInit() {
    this.logger.debug(`confirmation.component.ngOnInit`);
    // Gifts
    this.logger.debug("Retrieving gifts...");
    this.gifts = this.giftService.gifts;
    this.logger.trace("Gifts: ", this.gifts);

    const param = this.route.snapshot.paramMap.get("t");
    this.logger.debug("t: ", param);

    // Get first name from either SSO user or Donor
    if (this.donationService.ssoUser) {
      this.logger.debug("Sso user found");
      const ssoUser = this.donationService.ssoUser;
      this.logger.debug("ssoUser: ", ssoUser);
      this.firstName = ssoUser.firstName;

      // Create donor from sso user
      let donor: IDonor = {
        FirstName: ssoUser.firstName,
        lastName: ssoUser.lastName,
        organizationName: "",
        emailAddress: ssoUser.email,
        phone: ssoUser.workPhone,
        address: {
          country: { id: "", description: "", abbreviation: "" },
          state: { id: "", description: "", abbreviation: "", iso31662: "" },
          city: "",
          addressLines: "",
          postalCode: ""
        }
      };
      this.logger.trace("Donor: ", donor);
      this.donorService.donor = new Donor(donor);

      // Send acknowledgement
      this.donationService.sendAcknowledgement();

      this.localStorage.clear();

      this.showConfirmation = true;
      this.spinnerService.hide();
      this.logger.info("Payment complete");

    } else {
      // Otherwise, process unified checkout transaction
      const donor = this.donorService.donor;
      this.logger.trace("Donor: ", donor);
      this.firstName = donor.FirstName;

      this.showConfirmation = true;
      this.spinnerService.hide().then(() => {});

      // Retrieve donation information to send acknowledgement
      this.logger.debug("Retrieving donation...");
      this.donationService.getOnlineGivingDonation(this.donationService.donationId)
        .then(donationData => {
          this.logger.debug("Donation: ", donationData);

          // Send acknowledgement
          this.donationService.sendAcknowledgementWithDonation(donationData).then(() => {
            this.logger.debug("Acknowledgement sent");
          });

          // Get the cs transaction id from the response
          // const csTransactionId = this.donationService.processorTransactionId;
          // this.logger.debug("CS Transaction Id: ", csTransactionId);

          // Clear local storage
          this.localStorage.clear();

        });
    }
  }
}
