import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {FormBuilder, NgForm, Validators} from "@angular/forms";

import { GiftType } from "@interfaces/enums/gift-type.enum";
import { PaymentType } from "@interfaces/enums/payment-type.enum";
import { DonationService } from "@data/services/donation.service";
import { RecurrenceType } from "@interfaces/enums/recurrence-type.enum";

import { LoggerService } from "@core/services/logger.service";

@Component({
    selector: 'app-recurrence',
    templateUrl: './recurrence.component.html',
    styleUrls: ['./recurrence.component.css'],
    providers: [LoggerService],
    standalone: false
})
export class RecurrenceComponent implements OnInit {

  constructor(public readonly donationService: DonationService,
              private readonly logger: LoggerService,
              private readonly formBuilder: FormBuilder) {  }

  @Input() total: number;

  _recurring: boolean;
  @Input()
  set recurring(value: boolean) {
    this.logger.debug("recurrence.component.setRecurring | value: ", value);
    this._recurring = value;
    if (!this._recurring) {
      // Clear form
      this.form.reset();
      this.form.clearValidators();
      this.donationService.recurrence = null;
    } else {
      if (!this.donationService.recurrence) {
        // Set default startDate
        this.startDate.setValue(new Date());
        // Add validators
        this.frequency.setValidators([Validators.required]);
        this.startDate.setValidators([Validators.required]);
      }
    }
  }
  get recurring(): boolean {
    return this._recurring;
  }

  readonly recurringInfo: string = 'Thank you for becoming a sustaining donor! Your gift will be charged to the credit/debit card you provide in the amount and frequency indicated above. You may change or cancel your sustaining gift at any time by contacting Gift Processing at <a href="mailto:onlinegift@ucsd.edu">onlinegift@ucsd.edu</a>'
  readonly payrollDeductionInfo: string = "The Payroll Deduction option is for qualified UC San Diego employees only. You will be prompted to use your Single Sign On for this payment option. If you are not eligible, please select Secure Checkout instead.";
  // "<br/><br/>New recurring gifts via credit card are not active at this time. Please email <a href='mailto:giving@ucsd.edu'>giving@ucsd.edu</a> if you would like to be notified when this option is available.";

  giftType!: GiftType;
  GiftType = GiftType;
  paymentType!: PaymentType;
  PaymentType = PaymentType;
  RecurrenceType = RecurrenceType;

  submitting: boolean = false;

  recurrenceOptions: string[];

  minDate: Date = new Date();

  form = this.formBuilder.group({
    frequency: [null, [Validators.required]],
    startDate: [null, [Validators.required]]
  });

  get frequency(): any { return this.form.get("frequency"); }
  get startDate(): any { return this.form.get("startDate"); }

  ngOnInit() {
    this.logger.debug("recurrence.component.ngOnInit");

    // Get recurrence options from enum
    this.recurrenceOptions = Object.keys(this.RecurrenceType).filter(f => !isNaN(Number(f)));

    // Subscribe to donationService.GiftType changes and update local variable
    this.donationService.giftType$.subscribe(giftType => {
      this.logger.debug("recurrence.component.giftType$.subscribe | giftType: ", giftType);
      this.giftType = giftType;
    });

    // Subscribe to donationService.PaymentType changes and update local variable
    this.donationService.paymentType$.subscribe(paymentType => {
      this.logger.debug("recurrence.component.paymentType$.subscribe | paymentType: ", paymentType);
      this.paymentType = paymentType;
      // If payment type is payroll deduction remove validators
      if (this.paymentType === PaymentType.PayrollDeduction) {
        this.frequency.clearValidators();
        this.startDate.clearValidators();
        this.frequency.updateValueAndValidity();
        this.startDate.updateValueAndValidity();
        this.logger.debug(`recurrence.component.paymentType$.subscribe | formValid: ${this.form.valid}`)

        // Set frequency to monthly
        this.frequency.setValue(RecurrenceType.Monthly);

      } else {
        // Add validators
        this.frequency.setValidators([Validators.required]);
        this.startDate.setValidators([Validators.required]);
        this.frequency.updateValueAndValidity();
        this.startDate.updateValueAndValidity();
        this.logger.debug(`recurrence.component.paymentType$.subscribe | formValid: ${this.form.valid}`)

        // Clear frequency
        this.frequency.setValue(null);


      }
    });

    // Check for existing recurrence
    if (this.donationService.recurrence) {
      this.logger.debug("recurrence.component.ngOnInit | Existing recurrence: ", this.donationService.recurrence);

      // get values and populate form
      this.frequency.setValue(this.donationService.recurrence.frequency);
      this.startDate.setValue(this.donationService.recurrence.startDate);

    }

  }

}
