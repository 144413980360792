import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, NgForm } from "@angular/forms";

import { DonationService } from "@data/services/donation.service";
import { GiftType } from "@interfaces/enums/gift-type.enum";
import { LoggerService } from "@core/services/logger.service";


@Component({
    selector: 'app-select-gift-type',
    templateUrl: './select-gift-type.component.html',
    styleUrls: ['./select-gift-type.component.css'],
    providers: [LoggerService],
    standalone: false
})
export class SelectGiftTypeComponent implements OnInit {

  constructor(public readonly donationService: DonationService,
              private readonly formBuilder: UntypedFormBuilder,
              private readonly logger: LoggerService) { }

  GiftType = GiftType;

  form: UntypedFormGroup;

  submitting: boolean = false;

  get giftType() { return this.form.get("giftType"); }

  ngOnInit() {
    this.logger.debug('select-gift-type.component.ngOnInit');
    this.form = this.formBuilder.group({
      giftType: ["", [Validators.required]]
    });

    // Get initial value
    this.giftType.setValue(this.donationService.giftType);

    // Watch for changes and update the donation object
    this.giftType.valueChanges.subscribe(value => {
      this.logger.debug(`select-gift-type.component.giftType.valueChanges | value: `, value);
      this.donationService.giftType = value;
      // If it is not a recurring then wipe out any recurring info
      if (value !== GiftType.Recurring) {
        this.logger.debug(`select-gift-type.component.giftType.valueChanges | Clearing recurrence`);
        this.donationService.recurrence = null;
      }
    });
  }

}
