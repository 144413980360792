import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";

import { IGift } from "@interfaces/gift";
import { GiftService } from "@data/services/gift.service";
import { IDesignation } from "@interfaces/designation";
import { DesignationService } from "@data/services/designation.service"
import { HelperService } from "@core/services/helper.service";
import { NgxSpinnerService } from "ngx-spinner";
import swal from "sweetalert2";
import { LoggerService } from "@core/services/logger.service";

@Component({
    selector: 'app-gift-add-referral',
    templateUrl: './gift-add-referral.component.html',
    styleUrls: ['./gift-add-referral.component.css'],
    providers: [LoggerService],
    standalone: false
})
export class GiftAddReferralComponent implements OnInit {

  constructor(private spinnerService: NgxSpinnerService,
              private giftService: GiftService,
              private designationService: DesignationService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private logger: LoggerService) { }

  pageTitle: string = "You Have Selected The Following Fund:";

  addForm: UntypedFormGroup;

  fund: IDesignation;
  amount: number;
  appealId: string;

  submitting: boolean = false;
  attempts: number = 0;

  errorMessage: string;


  /**
   * Capture amount change in child component and update local value
   * @param amount Amount emitted by amount component
   */
  onAmountChanged(amount: number): void {
    this.amount = amount;
    this.amountControl.setValue(amount);
  }

  /**
   * Attempt to build a Gift object and save it
   */
  onSubmit(): void {
    this.logger.debug(`gift-add-referral.component.onSubmit`)
    this.submitting = true;

    // Check if form is valid
    if (!this.addForm.valid) {
      // Stop submission if form is not valid and increment counter
      this.attempts++;
      this.logger.debug('gift-add-referral.component.onSubmit | Form is not valid')
      return;
    }

    // Build gift object
    let gift: IGift = { amount: this.amount, designation: this.fund, appealId: this.appealId };

    this.logger.trace('gift-add-referral.component.onSubmit | gift: ', gift)

    this.giftService.addGift(gift).then(
      data => {
        this.logger.trace('gift-add-referral.component.onSubmit | addGift response: ', data)
        swal.fire({
          title: "Added!",
          text: `Your gift for $${gift.amount} to ${this.fund.publicName} has been saved`,
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-swal-confirm-button'
          }
        });
        this.router.navigateByUrl("/review");
        HelperService.goToTop();
      },
      error => {
        this.errorMessage = error;
        this.logger.error("", error);
      });

  }

  get amountControl() { return this.addForm.get("amount"); }


  ngOnInit() {
    this.logger.debug("gift-add-referral.component.ngOnInit")
    this.logger.loggerReady().then(
      data => {
        this.addForm = this.formBuilder.group({
          amount: ["", [Validators.required, Validators.min(1), Validators.max(99999)]]
        });


        // Get fund id from parameter
        const id = this.route.snapshot.paramMap.get("id");

        // Try to get an appeal id if one exists
        this.appealId = this.route.snapshot.paramMap.get("appealId");
        this.logger.debug(`gift-add-referral.component.ngOnInit | appealId: ${this.appealId}`)

        if (id) {
          // Load fund information
          this.designationService.getDesignation(id).then(
            data => {
              this.logger.trace('gift-add-referral.component.ngOnInit | getDesignation response: ', data)
              this.fund = data;

              // Stop spinner
              this.spinnerService.hide();
            });
        } else {
          // If there isn't a fund id in the parameter send them to the normal add gift page
          this.router.navigateByUrl("/add-gift");
        }
      },
      err => {
        this.logger.error("", err);
      });


  }

}
