import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { ReplaySubject } from "rxjs";
import { take } from "rxjs/operators";
import { MatSelect } from '@angular/material/select';
import { NgxSpinnerService } from "ngx-spinner";

import { IState } from "@interfaces/state"
import { ICountry } from "@interfaces/country";
import { LoggerService } from "@core/services/logger.service";
import { CountryService } from "@data/services/country.service";
import { ICountryViewModel } from "@interfaces/country-view-model";

import swal from "sweetalert2";
import { ConstantsService } from "@core/services/constants.service";

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.css'],
    providers: [LoggerService],
    standalone: false
})
export class AddressComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Output() stateChanged = new EventEmitter<boolean>();
  @Output() addressCaptionChanged = new EventEmitter<boolean>();

  errorMessage: string;

  states: IState[];
  countries: ICountry[];

  filteredCountries: ReplaySubject<ICountry[]> = new ReplaySubject<ICountry[]>(1);
  filteredStates: ReplaySubject<IState[]> = new ReplaySubject<IState[]>(1);

  countryCtrl: UntypedFormControl = new UntypedFormControl();
  countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  stateCtrl: UntypedFormControl = new UntypedFormControl();
  stateFilterCtrl: UntypedFormControl = new UntypedFormControl();

  @ViewChild("selectCountry", { static: true }) selectCountry: MatSelect;
  @ViewChild("selectState", { static: true }) selectState: MatSelect;

  zipPlaceholder: string;
  addressLinesPlaceholder: string;
  cityPlaceholder: string;
  statePlaceholder: string;
  showPostalCode: boolean = true;
  showAddressCaption: boolean = true;
  showState: boolean = true;

  public constants = ConstantsService;

  constructor(private countryService: CountryService,
              private spinnerService: NgxSpinnerService,
              private logger: LoggerService) {
    this.logger.debug("address.component.constructor");
  }

  // Form field getters for error handling
  get city() { return this.form.get("city"); }
  get addressLines() { return this.form.get("addressLines"); }
  get postalCode() { return this.form.get("postalCode"); }
  get country() { return this.form.get("country"); }
  get state() { return this.form.get("state"); }

  /**
   * Filter list of countries based on value entered in the search field
   */
  private filterCountries() {
    this.logger.debug('address.component.filterCountries');
    if (!this.countries) {
      return;
    }
    // Get the value from the search field
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // Filter the countries
    this.filteredCountries.next(
      this.countries.filter(country => country.description.toLowerCase().includes(search))
    );
  }

  /**
   * Filter states based on the value entered in the search field
   */
  private filterStates() {
    this.logger.debug('address.component.filterStates');
    if (!this.states) {
      return;
    }
    // Get the value from the search field
    let search = this.stateFilterCtrl.value;
    this.logger.debug("Filter: ", search);
    if (!search) {
      this.filteredStates.next(this.states.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // Filter the countries
    this.filteredStates.next(
      this.states.filter(state => state.description.toLowerCase().includes(search))
    );
  }

  /**
   * Watch for updates to the country select list and update the state list, labels, and form
   */
  private onCountryChanges(): void {
    this.logger.debug('address.component.onCountryChanges');
    this.countryCtrl.valueChanges.subscribe(
      countryControlData => {
        this.spinnerService.show(undefined, ConstantsService.defaultSpinnerOptions);

        this.logger.debug("address.component.onCountryChanges | Country updated: ", countryControlData);
        // If the updated value is different than the current value update and reset state list
        if (countryControlData !== this.country.value) {
          this.form.patchValue({ country: countryControlData });

          // Retrieve Country Detail
          this.countryService.getCountry(countryControlData.id).then(
            countryViewModel => {
              this.logger.trace("address.component.onCountryChanges | CountryViewModel: ", countryViewModel);
              this.states = countryViewModel.states;
              this.logger.trace("address.component.onCountryChanges | this.states: ", this.states);
              this.logger.trace("address.component.onCountryChanges | States length: ", this.states.length);
              this.filteredStates.next(this.states.slice());

              if (this.states.length > 0) {
                // Clear any existing selection on state select when a country has state list, if a country has no state, we don't reset it as the value is set by logic for countries with no state
                this.stateCtrl.reset();
              }

              // Update captions
              this.updateAddressCaptions(countryViewModel);

              // Handle showing of states and postal codes
              this.updateShowStatePostalCode(countryViewModel);

              this.spinnerService.hide();
            }, error => {
              this.spinnerService.hide();
              swal.fire({
                title: "An Error Occurred",
                text: ConstantsService.processingErrorMessage,
                icon: "error",
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-swal-confirm-button'
                }
              });
              this.logger.fatal("address.component.onCountryChanges | An error occurred while calling the Country api", error);
            });
        } else {
          this.spinnerService.hide();
        }
      });
  }

  /**
   * Watch for changes to the state selection and update the form.
   * Also check if Armed Forces and emit boolean
   */
  private onStateChanges(): void {
    this.logger.debug('address.component.onStateChanges');
    this.stateCtrl.valueChanges.subscribe(
      data => {
        this.logger.debug("address.component.onCountryChanges | State updated: ", data);
        this.form.patchValue({ state: data });
        // Notify parent if state selected was Armed Forces
        if (data) {
          const armedForces = (data as IState).description.includes("Armed Forces");
          this.stateChanged.emit(armedForces);
        }
      });
  }

  ngOnInit() {
    this.logger.debug('address.component.ngOnInit');
    this.logger.trace("State: ", this.form.get("state").value);
    let countryId: string;

    this.spinnerService.show(undefined, ConstantsService.defaultSpinnerOptions);

    // Populate countries and filtered countries from api
    this.countryService.getCountries().then(
      countries => {
        this.countries = countries;
        this.filteredCountries.next(this.countries.slice());
        // Subscribe to value changes of filter and run filter method
        this.countryFilterCtrl.valueChanges
          .pipe().subscribe(
          () => {
            this.filterCountries();
          });
        // If there is not a value in form set default value to UNITED STATES
        this.logger.trace("address.component.ngOnInit | Country: ", this.country.value);
        if (!this.country.value) {
          this.countryCtrl.setValue(this.countries.find(c => c.id === ConstantsService.unitedStatesGuid));
          countryId = ConstantsService.unitedStatesGuid;
        } else {
          this.countryCtrl.setValue(this.country.value);
          countryId = this.country.value.id;
        }

        // Retrieve Country Detail
        this.countryService.getCountry(countryId).then(
          countryViewModel => {
            // Populate state drop down
            this.states = countryViewModel.states;
            this.filteredStates.next(this.states.slice());
            // Subscribe to value changes of filter and run filter method
            this.stateFilterCtrl.valueChanges
              .pipe().subscribe(
              () => {
                this.filterStates();
              });

            // If there is not a value in form set default value to California
            if (!this.state.value && countryId === ConstantsService.unitedStatesGuid) {
              this.stateCtrl.setValue(this.states.find(s => s.description === "California"));
            }
            else if (this.state.value !== ConstantsService.noStateObj) {
              this.logger.debug("address.component.ngOnInit | The form \"state\" value: ", this.state.value);
              // Retrieve state from this list populating control and set the control to its value
              this.stateCtrl.setValue(this.states.find(s => s.id === (this.state.value).id));
            }

            // Update captions
            this.updateAddressCaptions(countryViewModel);

            // Handle showing of states and postal codes
            this.updateShowStatePostalCode(countryViewModel);
          }, error => {
            this.spinnerService.hide();
            swal.fire({
              title: "An Error Occurred",
              text: ConstantsService.processingErrorMessage,
              icon: "error",
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-swal-confirm-button'
              }
            });
            this.logger.fatal("address.component.ngOnInit | An error occurred while calling the States api", error);
          });
      }, error => {
        this.spinnerService.hide();
        swal.fire({
          title: "An Error Occurred",
          text: ConstantsService.processingErrorMessage,
          icon: "error",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-swal-confirm-button'
          }
        });
        this.logger.fatal("address.component.ngOnInit | An error occurred while calling the Countries api", error);
      });

    // Subscribe to country list changes
    this.onCountryChanges();

    // Subscribe to state list changes
    this.onStateChanges();
  }

  ngAfterViewInit() {
    this.logger.debug('address.component.ngAfterViewInit');
    this.filteredCountries
      .pipe(take(1)).subscribe(() => {
      // Setting the compareWith property to a comparison function
      // triggers initializing the selection according to the initial value of
      // the form control. This needs to be done after the filteredEntities are loaded initially
      // and after the mat-option elements are available
      if (this.selectCountry)
        this.selectCountry.compareWith = (a: ICountry, b: ICountry) => a.id === b.id;
    });

    this.filteredStates
      .pipe(take(1)).subscribe(() => {
      // Setting the compareWith property to a comparison function
      // triggers initializing the selection according to the initial value of
      // the form control. This needs to be done after the filteredEntities are loaded initially
      // and after the mat-option elements are available
      if (this.selectState)
        this.selectState.compareWith = (a: IState, b: IState) => a.id === (b ? b.id : "");
    });
  }

  /**
   * Handles updating the address captions of the form based on the country provided
   * @param countryViewModel
   */
  updateAddressCaptions(countryViewModel: ICountryViewModel): void {
    this.logger.debug(`address.component.updateAddressCaptions | countryViewModel: `, countryViewModel);
    // Update address captions
    this.logger.debug("Address captions: ", countryViewModel.addressCaption);
    // Update placeholders of address, state, and zip
    this.zipPlaceholder = countryViewModel.addressCaption.PostCode;
    this.addressLinesPlaceholder = countryViewModel.addressCaption.AddressLines;
    this.cityPlaceholder = countryViewModel.addressCaption.City;
    this.statePlaceholder = countryViewModel.addressCaption.State;

    // Show or hide Zip Code field base on Address Caption
    if (countryViewModel) {
      const noPostalCode = countryViewModel.addressCaption.PostCode.includes(ConstantsService.noPostalCodeVal);
      this.showAddressCaption = (!noPostalCode);
      this.logger.trace("address.component.updateAddressCaptions | showAddressCaptions: ", this.showAddressCaption);
    }
  }

  /**
   * Handles updating the visibility of the state and postal code
   * fields of the form based on the country provided
   * @param countryViewModel
   */
  updateShowStatePostalCode(countryViewModel: ICountryViewModel): void {
    this.logger.debug(`address.component.updateShowStatePostalCode | countryViewModel: `, countryViewModel);
    // Handle no state and no postal code logic
    this.showState = countryViewModel.hasStates;
    this.showPostalCode = countryViewModel.hasZip;

    this.logger.debug("address.component.updateShowStatePostalCode | ShowState: ", this.showState);

    if (!this.showState) {
      this.state.setValue(ConstantsService.noStateObj);
      this.logger.debug("address.component.updateShowStatePostalCode | state value: ", this.state.value);
    }

    if (!this.showPostalCode || !this.showAddressCaption) {
      this.postalCode.setValue(`${ConstantsService.noPostalCodeVal}`);
      this.logger.debug("address.component.updateShowStatePostalCode | postalCode value: ", this.postalCode.value);
    }
    else if (this.showPostalCode && this.form.get("postalCode").value == `${ConstantsService.noPostalCodeVal}`) {
      // Reset the Postal Code value to blank when country changed that from no zip code exists for country to one that has a zip code
      this.logger.debug("Reset the zip code to empty from \"Do not use\" when country changed to one that has a postal code.");
      this.postalCode.setValue("");
    }
  }
}
