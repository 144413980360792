
<div class="card">
  <div class="card-body">
    <div class="thankYouHero" *ngIf="firstName && showConfirmation" style="background-image: url(../../../../giving/bundles/assets/images/graduate-3.png)">
      <h1>Thanks, {{ firstName }}!</h1>
    </div>

    <div class="" *ngIf="!gifts || !showConfirmation">
      <strong>{{ loadingMsg }}</strong>
    </div>

    <div class="d-flex" *ngIf="showConfirmation">
      <div class="row p-3" *ngIf="gifts">
        <div>
          <div class="mergeField">
            Thank you for your gift! Your generous contribution helps UC San Diego unleash a diverse community of doers: those who imagine unexpected answers that can transform humanity for the better.
          </div>

          <div id="giftList" *ngIf="gifts.length > 1" class="font-weight-bold pt-3">
            Your gift will be supporting:
            <ul>
              <li *ngFor="let gift of gifts">
                {{ gift.designation.publicName }}
              </li>
            </ul>
          </div>

          <div class="font-italic pt-4 receiptInfo">
            <p>Your official gift receipt will be delivered soon to the email address you provided when you made your gift. Should you have any questions about your electronic receipt, or need to modify or cancel a recurring gift, please email <a href="mailto:onlinegift@ucsd.edu">onlinegift&#64;ucsd.edu</a>.</p>
          </div>

          <p class="pt-4">
            Check whether an employer is a participating company and view its specific matching gift forms, guidelines, and instructions by using the search box below.
          </p>

          <div>
            <app-matching-gift-search></app-matching-gift-search>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


