import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IGift } from "@interfaces/gift";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { GiftService } from "@data/services/gift.service";
import { IDesignation } from "@interfaces/designation";
import { LoggerService } from "@core/services/logger.service";
import { HelperService } from "@core/services/helper.service";
import { ConstantsService } from "@core/services/constants.service";
import { Title } from '@angular/platform-browser';

import swal from 'sweetalert2';

import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: 'app-gift-add',
    templateUrl: './gift-add.component.html',
    styleUrls: ['./gift-add.component.css'],
    providers: [LoggerService],
    standalone: false
})
export class GiftAddComponent implements OnInit {

  private amountTarget: ElementRef;

  @ViewChild("amountTarget", { static: false }) set AmountTarget(value: ElementRef) {
    if (value) {
      this.amountTarget = value;
    }
  }

  pageTitle: string = "Giving Details";
  addForm: UntypedFormGroup;
  errorMessage: string;

  designationName: string;

  appealId: string;

  submitting: boolean = false;
  attempts: number = 0;

  constructor(private formBuilder: UntypedFormBuilder,
              private giftService: GiftService,
              private spinnerService: NgxSpinnerService,
              private router: Router,
              private route: ActivatedRoute,
              private logger: LoggerService,
              private titleService: Title) {
    this.titleService.setTitle(`${ConstantsService.pageTitle} Gift Details`);
  }

  /**
   * Attempt to add the gift to the cache
   */
  onSubmit() {
    this.logger.debug(`gift-add.component.onSubmit`);

    this.submitting = true;

    // Check if form is valid
    if (!this.addForm.valid) {
      // Stop submission if form is not valid and increment counter
      this.attempts++;
      this.logger.debug(`gift-add.component.onSubmit | Attempts: ${this.attempts}`);
      return;
    }

    let gift: IGift = { amount: +this.amount.value, appealId: this.appealId };
    let designation: IDesignation = <IDesignation>this.addForm.get("fundId").value;
    gift.designation = designation;

    this.logger.trace(`gift-add.component.onSubmit | Designation:`, designation);

    this.giftService.addGift(gift).then(
      data => {
        this.logger.debug(`gift-add.component.onSubmit | Gift added:`, data);
        swal.fire({
          title: "Added!",
          text: `Your gift for $${gift.amount} to ${this.designationName} has been saved`,
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-swal-confirm-button'
          }
        });
        this.router.navigateByUrl("/review");
        HelperService.goToTop();
      },
      error => {
        this.errorMessage = error;
        this.logger.error(`gift-add.component.onSubmit | Error adding gift:`, error);
      });
  }

  /*** Nested component event listeners ***/

  /**
   * Catch amount changed event emitted by child component and set local amount value
   * @param amount value sent out by child form
   */
  onAmountChanged(amount: number) {
    this.logger.debug(`gift-add.component.onAmountChanged | amount: ${amount}`);
    this.amount.setValue(amount);
  }

  /**
   * Catch fund selected by search results or fund selector
   * @param selectedFund
   */
  onFundSelected(selectedFund: IDesignation) {
    this.logger.debug(`gift-add.component.onFundSelected | Selected fund:`, selectedFund);
    this.fundId.patchValue(selectedFund);
    this.designationName = selectedFund.publicName;

    // Scroll to the Amount selector when a fund has been selected
    if (this.amountTarget) {
      this.amountTarget.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  get fundId() { return this.addForm.get("fundId"); }
  get amount() { return this.addForm.get("amount"); }

  ngOnInit() {
    this.logger.debug(`gift-add.component.ngOnInit`);

    this.spinnerService.hide();

    // Try to get an appeal id if one exists
    this.appealId = this.route.snapshot.paramMap.get("appealId");
    this.logger.debug(`gift-add.component.ngOnInit | AppealId:`, this.appealId);

    // Create form
    this.addForm = this.formBuilder.group({
      amount: ["", [Validators.required, Validators.min(1), Validators.max(99999)]],
      fundId: ["", Validators.required]
    });
  }
}
