import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, FormBuilder, Validators } from "@angular/forms";

import swal from "sweetalert2";

import { HelperService } from "@core/services/helper.service";

@Component({
    selector: 'app-validation-failure',
    templateUrl: './validation-failure.component.html',
    styleUrls: ['./validation-failure.component.css'],
    standalone: false
})
export class ValidationFailureComponent implements OnInit {

  @Input() form: UntypedFormGroup;

  errorMsg: string = 'If you believe you have reached this message in error, please contact Gift Processing for assistance - <a href="mailto:onlinegift@ucsd.edu">onlinegift@ucsd.edu</a>';

  constructor(private helper: HelperService) { }

  ngOnInit() {

    // HACK: move this out of init so that it can be loaded more than once
    const errors = this.helper.getFormValidationErrors(this.form);
    const errorString = errors.join("<br>");
    swal.fire({
      title: "An Error Occurred",
      html: `The data could not be saved due to the following issues: <br>${errorString} <br><br>${this.errorMsg}`,
      icon: "error",
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-swal-confirm-button'
      }
    });
  }

}
